import React, {FC, ReactNode, ChangeEvent} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

interface Props {
  title: ReactNode;
  content: ReactNode;
  onChange?: (event: ChangeEvent<unknown>, expanded: boolean) => void;
  className?: string;
  expanded?: boolean;
  hideTitle?: boolean;
}

const BaseExpansionPanel: FC<Props> = ({title, content, onChange, className, expanded, hideTitle, ...other}) => (
  <Accordion onChange={onChange} className={className} expanded={expanded} {...other}>
    {!hideTitle && <AccordionSummary>{title}</AccordionSummary>}
    <AccordionDetails>{content}</AccordionDetails>
  </Accordion>
);

export default BaseExpansionPanel;
