import React from 'react';
import preloader from 'assets/images/preloader_blend.gif';

/**
 * Using styled components here breaks UI, since suspense returns a promise
 * For correct work, need to import the component directly,
 * as a result essence of suspense is lost
 */

const Loader = () => (
  <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
    <div className="d-flex align-items-center flex-column px-4">
      <img src={preloader} alt="" style={{width: '81px', height: '100px'}} />
    </div>
  </div>
);

export default Loader;
