import React, {ReactNode} from 'react';
import styled from 'styled-components';
import BaseIcon from './BaseIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';

interface Props {
  title?: string | ReactNode;
  actionName?: string;
  cancelName?: string;
  actionDisabled?: boolean;
  content: ReactNode | string;
  pendingContent?: ReactNode | string;
  handleOpen: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  className?: string;
  hideActionButton?: boolean;
  hideCloseButton?: boolean;
  additionalCloseButton?: boolean;
  maxWidth?: false;
}

const BaseConfirmModal = ({
  title,
  actionName,
  cancelName,
  content,
  handleOpen,
  handleClose,
  actionDisabled,
  handleSubmit,
  pendingContent,
  className,
  hideActionButton,
  additionalCloseButton,
  hideCloseButton,
  maxWidth,
  ...other
}: Props) => (
  <Wrapper open={handleOpen} onClose={handleClose} className={className || ''} maxWidth={maxWidth} {...other}>
    {additionalCloseButton && <CloseIcon icon={Close} onClick={handleClose} boxH={20} boxW={20} />}
    {title && <DialogTitle>{title}</DialogTitle>}
    {pendingContent || (
      <>
        <Content>{content}</Content>
        <DialogActions>
          <>
            {handleClose && !hideCloseButton && (
              <Button onClick={handleClose} data-qa-auto="modal-cancel-btn">
                {cancelName || 'Cancel'}
              </Button>
            )}
            {!hideActionButton && (
              <ActionButton onClick={handleSubmit} actionname={actionName} disabled={actionDisabled}>
                {actionName}
              </ActionButton>
            )}
          </>
        </DialogActions>
      </>
    )}
  </Wrapper>
);

const Wrapper = styled(Dialog)`
  & button {
    &:hover {
      background-color: ${({theme}) => theme.colors.grey015};
    }
  }
`;

const Content = styled(DialogContent)`
  & p {
    color: ${({theme}) => theme.colors.grey100};
  }
`;

const ActionButton = styled(Button)<{actionname?: string; disabled?: boolean}>`
  ${({
    theme: {
      colors: {red100, grey050, grey000},
    },
    actionname,
    disabled,
  }) => `
    ${
      actionname === 'Delete'
        ? `
    color: ${red100} !important;
    background: ${grey000} !important;
    border-color: ${red100} !important;
    `
        : ``
    }

    ${
      disabled
        ? `
      color: ${grey000} !important;
      background-color: ${grey050} !important;
      border-color: transparent !important;
    `
        : ``
    }
  `}
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
`;

const CloseIcon = styled(BaseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export default BaseConfirmModal;
