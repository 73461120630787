import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'utils/const';
import {getCountriesList} from 'api/admin';

export interface ICountry {
  country_id: number;
  country: string;
  code: string;
  continent_code: string;
  phone_number: string | null;
}

const useGetCountry = () =>
  useQuery(
    QUERY_KEYS.CHANGE_COUNTRY_FOR_ADMIN,
    () =>
      getCountriesList()
        .then(({data}) => data as ICountry[])
        .catch(() => []),
    {
      enabled: false,
    }
  );

export default useGetCountry;
