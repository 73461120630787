import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Centrifuge } from 'centrifuge';
import { MessageWSPayload, WEBSOCKET_EVENT } from 'store/pages/blendTalkStore';
import { MyProjectsWSPayload } from 'store/pages/myProjectsStore';
import { useUser } from 'context/UserData';
import useAuthToken from 'hooks/useAuthToken';
import { useStores } from 'hooks/useStores';

const WebsocketHandler = observer(() => {
  const { userData } = useUser();
  const { token } = useAuthToken();
  const centrifugeRef = useRef<Centrifuge | null>(null);

  const {
    blendTalkStore: { setToken, wsConstructor },
    myProjectsStore: { handleProjectsParamsChanged },
  } = useStores();

  useEffect(() => {
    if (token) {
      setToken(token);
      if (!centrifugeRef.current) {
        const centrifugeInstance = new Centrifuge(process.env.REACT_APP_BLEND_TALK_ENDPOINT as string, {
          getToken: async () => {
            if (token) {
              return token;
            }
            throw new Error('Token not available');
          }
        });

        centrifugeInstance.on('publication', ({ data }: { data: MessageWSPayload | MyProjectsWSPayload }) => {
          if (data.event === WEBSOCKET_EVENT.PARAMS_CHANGED) {
            userData?.id && handleProjectsParamsChanged(userData.id, data as MyProjectsWSPayload);
          } else {
            wsConstructor(data as MessageWSPayload);
          }
        });

        centrifugeInstance.connect();
        centrifugeRef.current = centrifugeInstance;
      }
    }
  }, [token, setToken, userData?.id, handleProjectsParamsChanged, wsConstructor]);

  useEffect(() => {
    return () => {
      centrifugeRef.current?.disconnect();
    };
  }, []);

  return <div />;
});

export default WebsocketHandler;
