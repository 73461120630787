import {useQuery} from 'react-query';
import useConfig from 'hooks/useConfig';
import {QUERY_KEYS} from 'utils/const';
import api from 'api';

export default function useCurrencyQuery(currency: string, cb: any) {
  const {config} = useConfig();
  const token = config?.CSRFToken;

  return useQuery(
    QUERY_KEYS.CHANGE_CURRENCY_FOR_ADMIN,
    () => api.admin.changeCurrency(currency, token).then(({data}) => !data?.errors?.length),
    {
      enabled: false,
      onSuccess: cb,
    }
  );
}
