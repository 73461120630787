import React from 'react';
import {NavAdmin, NavUnlogged, NavTranslator, NavClient} from './Navs';
import {useUser} from 'context/UserData';
import {USER_ROLES} from 'utils/const';

const {admin, customer, translator} = USER_ROLES;

const HeaderNav = () => {
  const {userData} = useUser();
  const {type} = userData || {};

  switch (type) {
    case admin:
      return <NavAdmin />;
    case customer:
      return <NavClient />;
    case translator:
      return <NavTranslator />;
    default:
      return <NavUnlogged />;
  }
};

export default HeaderNav;
