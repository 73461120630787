import {getOHTConfig, getRegistry} from 'api/wizard';
import {QUERY_KEYS} from 'utils/const';
import {useQuery} from 'react-query';
import {RegistryConfig} from './types';
import {getBlendTalkFeature} from '../api/profile';

const BLEND_TALK_FEATURE_UUID = 'c9c30829-c300-11ed-b6df-0242ac120008';

const useRegistryConfig = (): RegistryConfig => {
  const {data: config = {}} = useQuery(
    QUERY_KEYS.CONFIG,
    async () => {
      const res = await getOHTConfig();
      return res?.results?.Config;
    },
    {
      staleTime: Infinity,
      suspense: true,
    }
  );

  const {data: session} = useQuery(
    QUERY_KEYS.SESSION,
    async () => {
      const res = await getRegistry();
      const isShowBlendTalkFeature = await getBlendTalkFeature(
        res?.results?.Session?.CSRFToken,
        BLEND_TALK_FEATURE_UUID
      );
      return {...res?.results?.Session, isShowBlendTalkFeature};
    },
    {
      staleTime: Infinity,
      suspense: true,
    }
  );

  const uploadConfig = config?.upload || {};

  return {
    config: {
      ...config,
      CSRFToken: session?.CSRFToken,
      sid: session?.sid,
      voucher: session?.voucher,
      snData: session?.snData,
      isShowBlendTalkFeature: session?.isShowBlendTalkFeature,
    },
    uploadConfig,
  };
};

export default useRegistryConfig;
