import React from 'react';
import {NavDropdown, NavDropdownProps} from 'react-bootstrap';

export function CustomNavDropdown(props: NavDropdownProps) {
  const [show, setShow] = React.useState(false);

  return (
    <NavDropdown
      show={show}
      onTouchStart={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      {...props}
    >
      {props.children}
    </NavDropdown>
  );
}
