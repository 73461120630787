import React, {FC, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import BaseButton from '../../BaseComponents/BaseButton';
import BaseIcon from '../../BaseComponents/BaseIcon';
import BaseSimpleInput from '../../BaseComponents/BaseSimpleInput';
import CurrencySelect from './CurrencySelect';
import {useUser} from 'context/UserData';
import {useClearUserDetails} from './useUserDetailsQuery';
import {SanitizeHtml} from 'utils/HtmlSanitize';
import {AdminProject} from './useProjectAction';
import {IUserDetails, IEmptyUser} from './useUserAction';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';
import {font_small} from 'theme/fonts';
import Consts from './AdminActionsConsts';
import CountrySelect from './CountrySelect';

const {PROJECT_ERROR, ACT, GO, DETAILS, ACT_AS, RESTORE, ADMIN_LABEL, PROJECT_PLACEHOLDER, USER_PLACEHOLDER, LOADING} =
  Consts;

interface ActionsProps {
  project: AdminProject;
  handleProjectChange: (value: string) => void;
  redirectToTheProject: () => void;
  isProjectLoading: boolean;
  handleUserChange: (value: string) => void;
  isUserLoading: boolean;
  userIdFromInput: string;
  userError: string;
  actAsUser: () => void;
  restoreAdmin: () => void;
  isActingLikeUser?: boolean;
  refetch: () => void;
  UserDetails: IUserDetails | null;
  userPreview: IEmptyUser;
}

const Actions: FC<ActionsProps> = ({
  project,
  handleProjectChange,
  redirectToTheProject,
  isProjectLoading,
  handleUserChange,
  isUserLoading,
  userIdFromInput,
  userError,
  actAsUser,
  restoreAdmin,
  isActingLikeUser,
  refetch,
  UserDetails,
  userPreview,
}) => {
  const {userData} = useUser();
  const clearUserDetails = useClearUserDetails();
  const isLink = useMemo(() => (value: string) => typeof value === 'string' && value?.startsWith('<a'), []);
  const [hasExistAnswer, setExistAnswer] = useState<boolean>(false);

  const handleChangeProject = useCallback(
    (value: string) => {
      handleProjectChange(value);
      setExistAnswer(true);
    },
    [handleProjectChange]
  );

  return (
    <Wrapper>
      <FormControl>
        <Input
          value={project.id}
          type="number"
          onChange={({target: {value}}) => handleChangeProject(value)}
          placeholder={PROJECT_PLACEHOLDER}
        />
        <Button disabled={!project.exist || isProjectLoading} onClick={redirectToTheProject}>
          {GO}
        </Button>
        {!project.exist && hasExistAnswer && !isProjectLoading && project.id && <div>{PROJECT_ERROR}</div>}
      </FormControl>
      <FormControl>
        <Input
          value={userIdFromInput}
          onChange={({target: {value}}) => handleUserChange(value)}
          placeholder={USER_PLACEHOLDER}
        />

        <Button disabled={!userPreview?.ID || !!userError} onClick={actAsUser}>
          {ACT}
        </Button>
        {userIdFromInput && !userError && !isUserLoading && (
          <Button disabled={!userPreview?.ID || isUserLoading || !!userError} onClick={refetch}>
            {DETAILS}
          </Button>
        )}
        {userIdFromInput && userError && !isUserLoading && (
          <Error dangerouslySetInnerHTML={{__html: SanitizeHtml(userError)}} />
        )}
        {isUserLoading && LOADING}
      </FormControl>
      <div>{userPreview?.eMail || ''}</div>
      {isActingLikeUser ? (
        <AdminContent>
          <div>{`${ACT_AS} (ID: ${userData?.id})`} </div>
          <Button onClick={restoreAdmin}>{RESTORE}</Button>
        </AdminContent>
      ) : (
        <div>{ADMIN_LABEL}</div>
      )}
      <CurrencySelect />
      <CountrySelect />

      {UserDetails?.ID && (
        <DetailsContainer>
          <CloseDetailButton aria-label="close" onClick={clearUserDetails}>
            <CloseIcon icon={Close} boxW={20} boxH={20} />
          </CloseDetailButton>
          {Object.entries(UserDetails).map(([key, value]) => (
            <Row key={key}>
              <span>{key}:</span>

              {isLink(value) ? (
                <Column
                  dangerouslySetInnerHTML={{
                    __html: SanitizeHtml(value),
                  }}
                />
              ) : (
                <Column>{value}</Column>
              )}
            </Row>
          ))}
        </DetailsContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CloseDetailButton = styled(BaseButton)`
  position: absolute;
  right: 0;
  top: -35px;
  padding: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.grey060};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({theme}) => theme.colors.grey100};
  }
`;

const AdminContent = styled.div`
  margin: 10px 0;
`;

const FormControl = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  column-gap: 10px;

  &button {
    height: 30px;
  }
`;

const Input = styled(BaseSimpleInput)`
  max-width: 115px;
  height: 30px;
`;

const CloseIcon = styled(BaseIcon)`
  width: 20px;
  height: 20px;
`;

const DetailsContainer = styled.div`
  ${font_small};
  position: relative;
`;

const Button = styled(BaseButton)`
  ${font_small};
  background: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  border-radius: 5px;
  height: 32px;
  width: auto;
  min-width: auto;

  &:hover {
    background: ${({theme}) => theme.colors.blue090};
  }

  &:disabled {
    background: rgb(218, 219, 252);
    cursor: not-allowed;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin-bottom: 2px;
`;

const Column = styled.div`
  margin-left: auto;
`;

const Error = styled.div`
  color: ${({theme}) => theme.colors.red100};
`;

export default Actions;
