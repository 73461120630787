import React, {useState} from 'react';
import useUserDataApi from 'hooks/useUserDataApi';
import useUserQuery from 'hooks/useUserQuery';
import {UserData, AdminSubmarineData} from 'pages/Wizard/types';
import {HandleLogin, HandleSignUp, handleResetPassword as resetPassword, HandleLogout} from 'hooks/useUserDataApi';

interface IUserDataContext {
  userData: UserData | null;
  createdUserId: string | null;
  isLoggedIn: boolean;
  adminSubmarine: AdminSubmarineData;
  updateUserData: () => void;
}

interface IUserAuthContext {
  handleLogIn: HandleLogin;
  handleSignUp: HandleSignUp;
  handleLogout: HandleLogout;
  handleResetPassword: resetPassword;
  needTwoFactorAuth: boolean;
  errorMessages: any;
  setErrorMessages: React.Dispatch<React.SetStateAction<{[key: string]: any}>>;
  isFetchingAuth: boolean;
}

const UserDataContext = React.createContext<IUserDataContext | undefined>(undefined);
const UserAuthContext = React.createContext<IUserAuthContext | undefined>(undefined);

function UserProvider({children}: {children: any}) {
  const [isAdminActionsOpen, setAdminActionsOpen] = useState(false);
  const openAdminActions = () => setAdminActionsOpen(true);
  const closeAdminActions = () => setAdminActionsOpen(false);
  const [subX, setSubmarineX] = useState(300);
  const [subY, setSubmarineY] = useState(600);
  const setSubLocation = (x: number, y: number) => {
    setSubmarineX(x);
    setSubmarineY(y);
  };

  const [dimensions, setDimensions] = useState({
    minWidth: '350px',
    minHeight: '450px',
  });

  const {data: userDataResponse} = useUserQuery();

  const userData = userDataResponse?.data?.results?.User ?? undefined;

  const isLoggedIn = !!userData;

  const {
    createdUserId,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    needTwoFactorAuth,
    errorMessages,
    setErrorMessages,
    isFetchingAuth,
    updateUserData,
  } = useUserDataApi();

  const adminSubmarine = {
    isAdminActionsOpen,
    closeAdminActions,
    openAdminActions,
    subX,
    subY,
    setSubLocation,
    dimensions,
    setDimensions,
  };
  return (
    <UserDataContext.Provider
      value={{
        userData,
        createdUserId,
        isLoggedIn,
        adminSubmarine,
        updateUserData,
      }}
    >
      <UserAuthContext.Provider
        value={{
          handleLogIn,
          handleSignUp,
          handleLogout,
          handleResetPassword,
          needTwoFactorAuth,
          errorMessages,
          setErrorMessages,
          isFetchingAuth,
        }}
      >
        {children}
      </UserAuthContext.Provider>
    </UserDataContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserDataProvider');
  }
  return context;
}

function useAuth() {
  const context = React.useContext(UserAuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a UserAuthContext');
  }
  return context;
}

export {UserProvider, useUser, useAuth};
