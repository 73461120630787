import React, {useState, useEffect, useCallback, FC, useMemo} from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import IconButton from '@mui/material/IconButton';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ShoppingCartHeader from 'pages/ShoppingCart/components/ShoppingCartHeader';
import Logo from './HeaderLogo';
import AuthButtons from './AuthButtons';
import AdminHeaderDropdown from './components/AdminHeaderDropdown';
import AuthModal from 'components/AuthModal';
import {ModalMode} from '../../AuthModal/typesAuthModal';
import Nav from './components/Nav/HeaderNav';
import ChatActiveMessages from 'pages/BlendTalk/components/ChatActiveMessages';
import {useUser} from 'context/UserData';
import useConfig from 'hooks/useConfig';
import useMobile from 'hooks/useMobile';
import {USER_ROLES} from 'utils/const';
import {largeFontSize} from 'theme/fonts';

interface Props {
  purchaseOrdersCount: number;
  openSideBar?: any;
  sidebarToggle?: boolean;
}

const {translator, admin} = USER_ROLES;

// Layout header
const HeaderView: FC<Props> = ({purchaseOrdersCount, openSideBar, sidebarToggle}) => {
  const {isTablet} = useMobile();
  const isInAuthPage = window.location.pathname.includes('/auth');

  const [activeModalMode, setActiveModalMode] = useState<ModalMode>('');
  const {config} = useConfig();
  const {isLoggedIn, createdUserId, userData} = useUser();

  const handleCloseModal = useCallback(() => setActiveModalMode(''), []);

  const handleOpenModal = useCallback((type: ModalMode) => setActiveModalMode(type), []);

  useEffect(() => {
    handleCloseModal();
  }, [handleCloseModal, isLoggedIn, createdUserId, userData]);

  const isPaymentPage = useMemo(() => window.location.href.includes('/payment'), []);

  const renderAuthButtons = useCallback(
    () => !isInAuthPage && <AuthButtons handleOpenModal={handleOpenModal} />,
    [handleOpenModal, isInAuthPage]
  );

  const renderShoppingCart = useCallback(
    () => !isPaymentPage && <ShoppingCartHeader purchaseOrdersCount={purchaseOrdersCount} />,
    [isPaymentPage, purchaseOrdersCount]
  );

  const isAdmin = useMemo(() => userData?.type === admin, [userData]);

  return (
    <GeneralWrapper>
      <Wrapper>
        <Logo isDarkMode />
        <Nav />
        {isLoggedIn ? (
          <MenuBlock>
            <UserBlock istranslator={userData?.type === translator ? 1 : 0}>
              <ChatActiveMessages show />
              {renderShoppingCart()}
            </UserBlock>
            {isTablet && !isPaymentPage && (
              <MenuBtn onClick={openSideBar} data-autotest-attr="hamburger">
                {!sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
              </MenuBtn>
            )}
            {isAdmin && <AdminHeaderDropdown />}
          </MenuBlock>
        ) : (
          <div>{renderAuthButtons()}</div>
        )}
      </Wrapper>

      {activeModalMode && (
        <AuthModal
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          mode={activeModalMode}
          config={config}
          registrationPage=""
        />
      )}
    </GeneralWrapper>
  );
};

const GeneralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
`;

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 3;

  & .nav-item {
    & a {
      ${largeFontSize};
      color: ${({theme}) => theme.colors.grey000};
      font-weight: 400;

      &:hover {
        color: rgb(138, 136, 255);
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0 25px;

    .nav {
      display: none;
    }
  }
`;

const UserBlock = styled.div<{istranslator: number}>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: ${({istranslator}) => (istranslator ? 'auto' : '80px')};
`;

const MenuBlock = styled.div`
  display: flex;
  align-items: center;
`;

const MenuBtn = styled(IconButton)`
  color: ${({theme}) => theme.colors.grey000};
  width: 50px;
`;

export default HeaderView;
