import React, {Component, ReactNode, PropsWithChildren} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import theme from '../../theme';
import Consts from './ErrorBoundaryContst';

const {ERROR_TITLE, BUTTON_TEXT} = Consts;

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundaryWithLogging extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {error: null};
  }

  componentDidCatch(error: Error): void {
    this.setState({
      error,
    });
  }

  handleResetError = () => {
    this.setState({error: null});
  };

  render(): ReactNode {
    if (this.state.error) {
      return (
        <Wrapper>
          <Container>
            <Title>{ERROR_TITLE}</Title>
            <Text>{this.state.error.toString()}</Text>
            <BaseButton onClick={this.handleResetError}>{BUTTON_TEXT}</BaseButton>
          </Container>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background-color: ${theme.colors.grey010};
  width: 100%;
  min-height: 50%;
  padding: 20px 0;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: ${theme.colors.red100};
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Text = styled.p`
  color: ${theme.colors.grey070};
  padding: 0 20px;
  box-sizing: border-box;
  text-align: justify;
`;

export default ErrorBoundaryWithLogging;
