export default {
  PROJECT_ERROR: 'Project does not exist',
  ACT: 'Act',
  GO: 'Go',
  DETAILS: 'Details',
  ACT_AS: 'Acting as',
  RESTORE: 'Restore admin rights',
  ADMIN_LABEL: 'You are currently admin',
  PROJECT_PLACEHOLDER: 'Go to project...',
  USER_PLACEHOLDER: 'User ID/Email...',
  LOADING: 'Loading...',
};
