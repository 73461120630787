import React, {useState, FC, useEffect} from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import {Alert} from './CurrencySelect';
import useGetCountry, {ICountry} from './useGetCountry';
import {resetAdminCountry, setAdminCountry} from 'api/admin';
import {getCountriesInfo} from 'api/wizard';

const CountrySelect: FC = () => {
  const [country, setCountry] = useState<string>('');
  const [isSelectVisible, setSelectVisible] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const {data: coutriesList, refetch} = useGetCountry();

  const toggleVisible = () => setSelectVisible((prev) => !prev);

  const handleClose = () => setSuccess(false);

  const handleResetAdminCountry = () => {
    resetAdminCountry()
      .then(() => {
        setSuccess(true);
        try {
          window.location.reload();
        } catch (error) {
          console.error("can't refresh the page after couтtry RESET");
        }
      })
      .catch(() => console.error('error on country RESET'));
  };

  const handleCountryChange = ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
    if (value) {
      setCountry(value);
      setAdminCountry({admin_locale: value})
        .then(() => {
          setSuccess(true);
          try {
            window.location.reload();
          } catch (error) {
            console.error("can't refresh the page after couтtry change");
          }
        })
        .catch(() => console.error('error on country change'));
    }
  };

  useEffect(() => {
    getCountriesInfo().then(({data: {countryByIp}}) => setCountry(countryByIp));
    refetch();
  }, []);

  return (
    <Box margin="10px 0px">
      <Button variant="outlined" onClick={toggleVisible}>
        Change country
      </Button>

      {isSelectVisible && (
        <CountryWrapper>
          <select onChange={handleCountryChange} value={country}>
            {coutriesList?.map(({country: label, code}: ICountry) => (
              <option key={code} value={code}>
                {label} {code ? `(${code})` : ''}
              </option>
            ))}
          </select>

          <Button variant="outlined" onClick={handleResetAdminCountry}>
            Reset
          </Button>
        </CountryWrapper>
      )}
      <Snackbar
        open={success}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            Country updated
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
};

const CountryWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export default CountrySelect;
