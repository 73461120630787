import {useState, useEffect} from 'react';
import {useMutation} from 'react-query';
import useRegistryConfig from 'hooks/useConfig';
import {useUser} from 'context/UserData';
import {useUserDetailsQuery} from './useUserDetailsQuery';
import api from 'api';
import useDebounce from 'hooks/useDebounce';
import {USER_ROLES} from 'utils/const';

export interface IUserDetails {
  ID: number;
  act: string;
  alerts: string;
  client_score: number;
  country_set_by_user: string;
  customer_credit_balance: number;
  customer_projects: string;
  customer_type: string;
  date_registered: string;
  email: string;
  full_name: string;
  logged_in: string;
  pricing: string;
  profile: string;
  profitability: string;
  registration_user_IP: string;
  status: string;
  subscription: string;
  translator: string;
  username: string;
  uuid: string;
}

export interface IEmptyUser {
  ID: string;
  UserName: string;
  eMail: string;
}

const emptyUser: IEmptyUser = {
  ID: '',
  UserName: '',
  eMail: '',
};

interface UserActionResult {
  error: string;
}

const useUserAction = () => {
  const [userIdFromInput, setUserIdFromInput] = useState<string>('');
  const [userPreview, setUserPreview] = useState<IEmptyUser>(emptyUser);
  const debouncedUserIdFromInput = useDebounce(userIdFromInput, 500);
  const [userError, setUserError] = useState<string>('');

  const {
    config: {legacyUrl = ''},
  } = useRegistryConfig();

  const {mutate: mutateIsUserExist, isLoading: isUserLoading} = useMutation(api.admin.postSearchUser, {
    onSuccess: (result: UserActionResult | IEmptyUser) => {
      if ('error' in result) {
        setUserError(result.error as string);
      } else {
        setUserError('');
        setUserPreview(result);
      }
    },
  });

  useEffect(() => {
    if (!debouncedUserIdFromInput) {
      setUserPreview(emptyUser);
    } else {
      const fd = new FormData();
      fd.append('userID', debouncedUserIdFromInput);
      mutateIsUserExist({fd, legacyUrl});
    }
  }, [debouncedUserIdFromInput, legacyUrl, mutateIsUserExist]);

  const {data: UserDetails = null, refetch} = useUserDetailsQuery(userPreview.ID);

  const {updateUserData, userData} = useUser();

  const isActingLikeUser = (userData || {}).type !== USER_ROLES.admin && userData?.isAdmin;

  const handleUserChange = (value = '') => {
    setUserIdFromInput(value);
  };

  const actAsUser = () => {
    api.admin.getUserToAct(userPreview.ID).then(({data}) => {
      if (data.status === 'OK') {
        updateUserData();
        return;
      }
      setUserError(typeof data === 'string' ? data : JSON.stringify(data || 'Unexpected error'));
    });
  };

  const restoreAdmin = () => {
    api.admin.restoreAdmin().then(({data}) => {
      if (data.status === 'OK') {
        updateUserData();
      }
    });
  };

  return {
    userIdFromInput,
    handleUserChange,
    userPreview,
    isUserLoading,
    userError,
    actAsUser,
    restoreAdmin,
    isActingLikeUser,
    UserDetails,
    refetch,
  };
};

export default useUserAction;
