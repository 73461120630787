import styled from 'styled-components';

export default styled.div<{horizontal?: boolean; dotted?: boolean}>`
  border-color: ${({theme}) => theme.colors.grey030};
  border-style: ${({dotted}) => (dotted ? 'dotted' : 'solid')};

  ${({horizontal = false}) =>
    horizontal
      ? `border-bottom: 1px`
      : `
        border-right: 1px;
        width: 0;
        height: 20px;
      `}
`;
