import React, {FC, useMemo} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useUser} from 'context/UserData';

interface TranslatorRouteProps {
  path?: string;
}

const HomeLayout: FC<TranslatorRouteProps> = () => {
  const {userData} = useUser();
  const location = useLocation();

  const userPath = useMemo(() => (userData?.type === 'Translator' ? '/dashboard' : '/cpanel'), [userData?.type]);
  const pathname = useMemo(() => (!userData ? '/auth' : userPath), [userData, userPath]);

  return <Navigate to={pathname} state={location.pathname} />;
};

export default HomeLayout;
