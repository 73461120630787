import React, {createRef, useState, useEffect, useCallback, useRef, useMemo} from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {WidgetInstance} from 'friendly-challenge';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PasswordRequirements from 'components/GeneralComponents/PasswordRequirements';
import SubmitButton from './SubmitButton';
import PasswordField from './PasswordField';
import ErrorMessageText from './ErrorMessageText';
import {ModalMode, AuthFormValue} from '../typesAuthModal';
import {useAuth} from 'context/UserData';
import {getCountriesInfo} from 'api/wizard';
import {isEmailValid, isFullNameValid} from 'pages/Registration/RegistrationUtils';
import useCountryByIp from 'hooks/useCountryByIp';
import {BaseConfig} from 'hooks/types';

declare global {
  interface Window {
    resetFriendlyCaptcha: any;
  }
}

type Props = {
  handleClose?: () => void;
  emailUpdatedHandler: (value: string) => void;
  mode: ModalMode;
  config: BaseConfig;
  registrationPage?: string;
  captchaService?: string;
  agreeTermsValue: boolean;
  emailParam?: string;
};

const Form = ({mode, config, emailUpdatedHandler, registrationPage, agreeTermsValue, emailParam}: Props) => {
  const {data: userCountryByIp = []} = useCountryByIp();

  const initialFormValue = {
    username: emailParam || '',
    email: emailParam || '',
    password: '',
    twoFactorAuthCode: '',
    recaptchaValue: '',
    agreeTerms: true,
    registrationPage: '',
    isTranslator: false,
    userType: 'customer',
    userCountry: '',
    captchaService: '',
  };

  const [formValue, setFormValue] = useState<AuthFormValue>(initialFormValue);
  const [inputPassword, setInputPassword] = useState<string>('');
  const [passwordApproved, setPasswordApproved] = useState<boolean>(false);

  const isChina = useMemo(() => ['CN', 'HK'].includes(userCountryByIp), [userCountryByIp]);

  const container = useRef<any>();
  const widget = useRef<any>();

  const isApproved = useCallback((value: boolean) => {
    setPasswordApproved(value);
  }, []);

  const {handleLogIn, handleSignUp, needTwoFactorAuth, errorMessages, setErrorMessages, isFetchingAuth} = useAuth();

  const {
    username,
    email,
    password,
    twoFactorAuthCode,
    recaptchaValue,
    agreeTerms,
    userType,
    userCountry,
    captchaService,
  } = formValue;

  const recaptchaRef = createRef<any>();

  const doneCallback = useCallback(
    (solution: string) => {
      if (solution) {
        setFormValue((prevValue) => ({
          ...prevValue,
          recaptchaValue: solution,
        }));

        setFormValue((prevValue) => ({
          ...prevValue,
          captchaService: 'friendly',
        }));

        window.resetFriendlyCaptcha = () => {
          if (isChina) {
            if (widget.current !== undefined) widget.current.reset();
          }
        };
      }
    },
    [isChina]
  );

  const errorCallback = useCallback((err: any) => {
    if (err) {
      setFormValue((prevValue) => ({
        ...prevValue,
        recaptchaValue: '0',
      }));
    }
  }, []);

  const {snData: {emailExists = false, email: socialEmail = null} = {}} = config;

  const updateSocialEmail = useMemo(
    () => () => {
      if (emailExists && socialEmail) {
        setFormValue((prevValue) => ({
          ...prevValue,
          email: socialEmail,
          username: socialEmail,
        }));
      }
    },
    [emailExists, socialEmail]
  );

  useEffect(() => {
    updateSocialEmail();
  }, [updateSocialEmail]);

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        doneCallback,
        errorCallback,
      });
    }

    return () => {
      if (widget.current !== undefined) widget.current.reset();
    };
  }, [container, doneCallback, errorCallback]);

  const signupMode = useMemo(() => mode === 'signup', [mode]);

  useEffect(() => {
    getCountriesInfo()
      .then(({data: {countryByIp}}) => {
        setFormValue((prevValue) => ({
          ...prevValue,
          userCountry: countryByIp,
        }));
      })
      .catch((err) => console.log(err));
  }, [mode]);

  useEffect(() => {
    setFormValue((prevValue) => ({
      ...prevValue,
      agreeTerms: agreeTermsValue,
    }));
  }, [agreeTermsValue]);

  const handleChangeValue = useCallback(
    (value: string | boolean, name: string) => {
      if (name === 'username') {
        if (!isFullNameValid(value as string)) {
          setErrorMessages((prevState) => ({...prevState, full_name: ['Incorrect full name']}));
        } else {
          emailUpdatedHandler(value as string);
          setErrorMessages({});
        }
      }

      if (name === 'email') {
        if (!isEmailValid(value as string)) {
          setErrorMessages((prevState) => ({...prevState, user_email: ['Incorrect email']}));
        } else {
          setErrorMessages({});
        }
      }

      name === 'password' && setInputPassword(value as string);

      setFormValue((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    },
    [emailUpdatedHandler, setErrorMessages]
  );

  let handleSubmit = () => {};
  const handlePreSubmit = useCallback(() => {
    if (isChina) {
      handleSubmit();
    } else {
      recaptchaRef?.current?.reset();
      recaptchaRef.current.execute();
    }
  }, [recaptchaRef, isChina]);

  const handleChangeRecaptcha = useCallback((value: string | null) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      recaptchaValue: value,
    }));
  }, []);

  useEffect(() => {
    recaptchaValue && !isChina && handleSubmit();
  }, [recaptchaValue, isChina]);

  const clearErrorMessages = useCallback(() => setErrorMessages({}), [setErrorMessages]);

  const handleResetForm = useCallback(() => {
    setFormValue(initialFormValue);
    updateSocialEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSocialEmail]);

  if (mode === 'signup') {
    handleSubmit = () => {
      handleSignUp({
        username,
        email,
        recaptchaValue,
        password,
        agreeTerms,
        userType,
        userCountry,
        config,
        registrationPage,
        captchaService,
      });
    };
  } else if (mode === 'login') {
    handleSubmit = () => {
      handleLogIn(
        {
          username,
          password,
          twoFactorAuthCode,
          recaptchaValue,
          captchaService,
        },
        config
      );
    };
  }

  useEffect(() => {
    clearErrorMessages();
    handleResetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  let submitButtonText = '';
  let modalName = '';
  if (signupMode) {
    submitButtonText = 'Sign Up';
    modalName = 'signup-modal';
  } else if (mode === 'login') {
    submitButtonText = 'Sign In';
    modalName = 'login-modal';
  } else if (mode === 'extraUserData') {
    submitButtonText = 'Continue';
  }

  const formAttr = {'data-qa-auto': modalName};

  //two-factor auth form
  if (mode === 'login' && needTwoFactorAuth) {
    return (
      <>
        <Input
          name="twoFactorAuthCode"
          value={twoFactorAuthCode}
          onChange={({target: {value}}) => handleChangeValue(value, 'twoFactorAuthCode')}
          label="Token from Google Authenticator"
          disabled={isFetchingAuth}
          fullWidth
        />
        <SubmitButton
          text={submitButtonText}
          handleSubmit={handleSubmit}
          disabled={isFetchingAuth}
          displayLoader={isFetchingAuth}
        />
      </>
    );
  }

  // login | registration form
  return (
    <FormItem {...formAttr}>
      {signupMode && (
        <Input
          name="username"
          value={username}
          onChange={({target: {value}}) => handleChangeValue(value, 'username')}
          fullWidth
          label="Full Name"
          error={!!errorMessages?.full_name?.length}
          helperText={errorMessages?.full_name?.[0]}
          disabled={isFetchingAuth}
          inputProps={{'data-qa-auto': 'username'}}
          variant="outlined"
        />
      )}
      <Input
        name="email"
        value={email}
        onChange={({target: {value}}) => {
          handleChangeValue(value, 'email');
          if (mode === 'login') {
            setFormValue((prevValue) => ({
              ...prevValue,
              username: value,
            }));
          }
        }}
        label="Email"
        fullWidth
        error={
          !!errorMessages?.user_email?.length ||
          !!errorMessages?.user?.length ||
          (mode !== 'signup' && !!errorMessages?.full_name?.length)
        }
        helperText={
          errorMessages?.user_email?.[0] ||
          errorMessages?.user?.[0] ||
          (mode !== 'signup' && errorMessages?.full_name?.[0])
        }
        disabled={isFetchingAuth}
        inputProps={{'data-qa-auto': 'email'}}
        variant="outlined"
      />
      <PasswordField
        password={password}
        handleChangeValue={handleChangeValue}
        errorMessages={errorMessages}
        disabled={isFetchingAuth}
        placeholder={mode === 'login' ? 'Password' : 'Password'}
      />
      {signupMode && (
        <>
          <PasswordRequirements password={inputPassword} onApproveChange={isApproved} />

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="isTranslator"
                  color="primary"
                  inputProps={{'aria-label': 'primary checkbox'}}
                  onChange={({target: {checked}}) => handleChangeValue(checked, 'isTranslator')}
                  onClick={() => {
                    setFormValue((prevValue) => ({
                      ...prevValue,
                      userType: !formValue.isTranslator ? 'translator' : 'customer',
                    }));
                  }}
                />
              }
              label="I'm a translator"
            />
          </FormControl>
        </>
      )}
      {isChina ? (
        <div style={{margin: '0 0 10px'}}>
          <div ref={container} className="frc-captcha" style={{width: '100%'}} data-sitekey="FCMLPLD9VK0ROBLC" />
        </div>
      ) : (
        <>
          {config?.captchaApiKey && ( //signup always needs captcha
            <CaptchaBlock>
              <ReCAPTCHA
                theme="dark"
                size="invisible"
                ref={recaptchaRef}
                sitekey={config?.captchaApiKey || ''}
                onChange={handleChangeRecaptcha}
              />
            </CaptchaBlock>
          )}
        </>
      )}
      <ErrorMessageText data-qa-auto="errmsg" message={errorMessages?.common} />
      <SubmitButton
        text={submitButtonText}
        handleSubmit={handlePreSubmit}
        disabled={isFetchingAuth || (!passwordApproved && mode === 'signup')}
        displayLoader={isFetchingAuth}
      />
    </FormItem>
  );
};

const CaptchaBlock = styled.div`
  margin-bottom: 15px;
`;

const Input = styled(TextField)`
  margin-bottom: 0.9rem;

  input {
    div > input {
      padding: 14.4px;
    }
    & p {
      margin-left: 0;
    }
  }
`;

const FormItem = styled.form`
  margin-top: 10px;
`;

export default Form;
