import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import BaseLink from 'components/BaseComponents/BaseLink';
import BaseImage from 'components/BaseComponents/BaseImage';
import BaseMemoHoc from 'components/BaseComponents/BaseMemoHoc';
import {useUser} from 'context/UserData';
import useConfig from 'hooks/useConfig';
import LightLogo from 'assets/icons_refactor/Common/blend-logo-white.svg';
import BlackLogo from 'assets/icons_refactor/Common/blend-logo-black.svg';

interface Props {
  isWizard?: boolean;
  isDarkMode: boolean;
}

const HeaderLogo: FC<Props> = ({isWizard = false, isDarkMode}) => {
  const {
    config: {appDomainURL},
  } = useConfig();
  const {isLoggedIn} = useUser();
  const logoUrl = useMemo(
    () => `https://${isWizard ? (isLoggedIn ? appDomainURL : `blendexpress.com/${window.location.search}`) : appDomainURL}`,
    [appDomainURL, isLoggedIn, isWizard]
  );

  return (
    <BaseLink href={logoUrl}>
      <Logo src={isDarkMode ? LightLogo : BlackLogo} />
    </BaseLink>
  );
};

const Logo = styled(BaseImage)`
  cursor: pointer;
`;

export default BaseMemoHoc(HeaderLogo);
