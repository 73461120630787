import React, {FC, ReactNode, ReactElement} from 'react';
import Tooltip, {TooltipProps} from '@mui/material/Tooltip';
import {createGlobalStyle} from 'styled-components';
import colors from 'theme/colors';

interface Props extends TooltipProps {
  children: ReactElement;
  title: ReactNode;
  dropdownClassName?: string;
  placement?: TooltipProps['placement'];
  disable?: boolean;
}

const BaseTooltip: FC<Props> = ({children, dropdownClassName, title, placement, disable, ...other}) => (
  <>
    <GlobalStyle />
    <Tooltip
      {...other}
      classes={{popper: dropdownClassName}}
      componentsProps={{
        tooltip: {
          className: dropdownClassName,
        },
      }}
      arrow
      title={title}
      placement={placement}
      disableHoverListener={disable}
      enterTouchDelay={0}
    >
      <div>{children}</div>
    </Tooltip>
  </>
);

const GlobalStyle = createGlobalStyle`
    .MuiTooltip-tooltip {
        background-color: ${colors.blue100};
        font-size: 13px;
        margin: 5px 0;

        & > span {
            &:before {
                color: ${colors.blue100};
            }
        }

        & a {
            color: ${colors.grey000};
            text-decoration: underline;
        }

        & ul.password-tooltip {
            margin: 5px 0 5px -20px;
        }
    }

    div.MuiTooltip-tooltip {
        min-height: 36px;
        display: flex;
        align-items: center;
        max-width: 200px;
        border-radius: 8px;
        top: 190px;

        @media (min-width: 960px) {
            top: 5px;
        }
    }

`;

export default BaseTooltip;
