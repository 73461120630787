import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'utils/const';
import api from 'api';

export default function useUserQuery() {
  return useQuery(QUERY_KEYS.USER_DATA, api.wizard.getUserData, {
    staleTime: Infinity,
    suspense: true,
  });
}
