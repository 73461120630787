import React, {FC} from 'react';
import {DesktopDatePicker, DesktopDatePickerProps} from '@mui/x-date-pickers/DesktopDatePicker';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import BaseDatepickerInput from './BaseDatepickerInput';

export type DatePickerValue = string | number | Date | null | undefined | any;

export interface BaseDatePickerProps
  extends Omit<DesktopDatePickerProps<DatePickerValue, DatePickerValue>, 'value' | 'onChange' | 'renderInput'> {
  value: DatePickerValue;
  onChange: (date: DatePickerValue, nextDate?: DatePickerValue) => void;
  'data-qa-auto'?: string;
  renderInput?: DesktopDatePickerProps<DatePickerValue, DatePickerValue>['renderInput'];
  mobile?: boolean;
}

const BaseDatePicker: FC<BaseDatePickerProps> = ({
  value,
  onChange,
  renderInput,
  mobile,
  'data-qa-auto': dataQaAuto,
  ...restProps
}) =>
  mobile ? (
    <MobileDatePicker
      {...restProps}
      data-qa-auto={dataQaAuto}
      value={value}
      onChange={onChange}
      renderInput={
        renderInput
          ? renderInput
          : (inputProps) => <BaseDatepickerInput {...inputProps} data-qa-auto={dataQaAuto} aria-label={'change date'} />
      }
    />
  ) : (
    <DesktopDatePicker
      {...restProps}
      data-qa-auto={dataQaAuto}
      value={value}
      onChange={onChange}
      renderInput={
        renderInput
          ? renderInput
          : (inputProps) => <BaseDatepickerInput {...inputProps} data-qa-auto={dataQaAuto} aria-label={'change date'} />
      }
    />
  );

export default BaseDatePicker;
