import {getCountriesInfo} from 'api/wizard';
import {QUERY_KEYS} from 'utils/const';
import {useQuery} from 'react-query';

const useCountryByIp = () => {
  return useQuery(
    QUERY_KEYS.COUNTRIES,
    async () => {
      const res = await getCountriesInfo();
      return res?.data?.countryByIp;
    },
    {
      staleTime: Infinity,
      suspense: true,
    }
  );
};

export default useCountryByIp;
