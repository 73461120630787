import React, {FC, createContext, ReactNode} from 'react';
import {useUser} from 'context/UserData';
import useTrackingState from './useTrackingState';

const TrackingContext = createContext<any | undefined>(undefined);

export interface Props {
  children: ReactNode;
}

const TrackingProvider: FC<Props> = ({children}) => {
  const {userData} = useUser();

  const value = useTrackingState(userData);

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
};

export {TrackingProvider, TrackingContext};
