import React, { ReactNode, useEffect, useMemo, useState, FC, lazy, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import AdminActions from './AdminActions/AdminActions';
import Header from './MainHeader/HeaderView';
import Footer from './Footer';
import useConfig from 'hooks/useConfig';
import useMobile from 'hooks/useMobile';
import { useStores } from 'hooks/useStores';
import { useUser } from 'context/UserData';
import { handleGridStyle } from './PageLayoutUtils';
import { Sidebar } from './Sidebar';

const TermsAndConditionsModal = lazy(() => import('components/TermsAndConditionsModal'));

interface PageLayoutProps {
  children: ReactNode;
  isHidesidebar?: boolean;
}

const PageLayout: FC<PageLayoutProps> = observer(({ children, isHidesidebar }) => {
  const { isLoggedIn, createdUserId, userData } = useUser();

  const { search } = useLocation();

  const {
    shoppingCartStore,
    layoutStore: { sidebarToggle, setSidebarToggle },
    profileStore: { getProfileData, setFlow },
  } = useStores();

  const {
    config: { CSRFToken },
  } = useConfig();

  const [initiallySelectedNav, setInitiallySelectedNav] = useState<string>('');

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const flow = urlParams.get('flow');
    if (flow) setFlow(flow);
  }, [urlParams, setFlow]);

  const { isLaptop } = useMobile();

  const { isAdmin = false } = userData ?? {};

  useEffect(() => {
    setSidebarToggle(!isLaptop);
    if (window.location.pathname !== '/payment/thankyou/') {
      sessionStorage.removeItem('successfulPurchase');
    }
  }, [isLaptop, setSidebarToggle]);

  const [purchaseOrdersCount, setPurchaseOrdersCount] = useState<number>(0);

  useEffect(() => {
    if (isLoggedIn || createdUserId) {
      const disposer = autorun(() => {
        shoppingCartStore.getShoppingCartCount();
      });
      return () => disposer();
    }
  }, [isLoggedIn, createdUserId, shoppingCartStore]);

  useEffect(() => {
    if (shoppingCartStore.shoppingCartCountIsLoaded) {
      setPurchaseOrdersCount(shoppingCartStore.shoppingCartCount);

      const cartTooltip = localStorage.getItem('shoppingCartTooltip');
      onbeforeunload = function () {
        if (!cartTooltip) {
          localStorage.setItem('shoppingCartTooltip', '1');
        }
      };
    }
  }, [shoppingCartStore.shoppingCartCountIsLoaded, shoppingCartStore.shoppingCartCount]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const openSideBar = useCallback((key?: string) => {
    key && setInitiallySelectedNav(key);
    setSidebarToggle(!sidebarToggle);
  },[sidebarToggle]);

  const isDemoProjectPage = useMemo(() => window.location.href.includes('/project/demo'), []);

  const sidebarSize: number = useMemo(() => (sidebarToggle ? 280 : 88), [sidebarToggle]);

  return (
    <Wrapper isDisplayed={isLaptop}>
      {!CSRFToken && <Loader />}
      {!isHidesidebar && (
        <Header
          openSideBar={openSideBar}
          purchaseOrdersCount={purchaseOrdersCount}
          sidebarToggle={sidebarToggle}
        />
      )}
      <Container
        sidebarToggle={sidebarToggle}
        sidebarSize={sidebarSize}
        demopage={isDemoProjectPage ? 1 : 0}
        isHidesidebar={isHidesidebar ? 1 : 0}
      >
        {!isHidesidebar && (
          <Sidebar
            initiallySelectedNav={initiallySelectedNav}
            setInitiallySelectedNav={setInitiallySelectedNav}
            openSideBar={openSideBar}
          />
        )}
        <Content>{children}</Content>
      </Container>
      {isAdmin && !isHidesidebar && <AdminActions />}
      {!isHidesidebar && <Footer />}

      <TermsAndConditionsModal />
    </Wrapper>
  );
});

const Wrapper = styled.div<{isDisplayed: boolean}>`
  height: 100%;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    grid-template-columns: 1fr;
    background: ${({isDisplayed, theme}) => `${isDisplayed ? theme.colors.grey000 : 'transparent'}`};
  }
`;

const Container = styled.div<{sidebarToggle: boolean; sidebarSize: number; demopage: number; isHidesidebar: number}>`
  display: grid;
  height: calc(100% - 150px);
  grid-template-columns: ${({sidebarSize, demopage, isHidesidebar}) =>
    handleGridStyle(demopage, sidebarSize, isHidesidebar)};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    grid-template-columns: 1fr;
    background: ${({
      sidebarToggle,
      theme: {
        colors: {grey000},
      },
    }) => `${sidebarToggle ? grey000 : 'transparent'}`};
  }
`;

const Content = styled.div`
  height: 100%;
  overflow: auto;
  min-height: calc(100vh - 86px);
  background-color: ${({theme}) => theme.colors.grey010};
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

export default PageLayout;
