import {FC, ReactNode} from 'react';
import {ReactComponent as SideStatement} from 'assets/icons_refactor/SideMenu/side-statement.svg';
import {ReactComponent as SideCredits} from 'assets/icons_refactor/SideMenu/credits.svg';
import {ReactComponent as SideMessages} from 'assets/icons_refactor/SideMenu/side-post.svg';
import {ReactComponent as SideInvoices} from 'assets/icons_refactor/SideMenu/side-invoices.svg';
import {ReactComponent as SideDashboard} from 'assets/icons_refactor/SideMenu/side-dashboard.svg';
import {ReactComponent as SideBalance} from 'assets/icons_refactor/SideMenu/round-statement.svg';
import {ReactComponent as SideCommunity} from 'assets/icons_refactor/SideMenu/side-community.svg';
import {ReactComponent as SideAccountInfo} from 'assets/icons_refactor/SideMenu/account-info.svg';
import {ReactComponent as SideLanguageSkills} from 'assets/icons_refactor/SideMenu/language.svg';
import {ReactComponent as SideExpertise} from 'assets/icons_refactor/SideMenu/star.svg';
import {ReactComponent as SideCertificates} from 'assets/icons_refactor/SideMenu/certificate.svg';
import {ReactComponent as SideAvailability} from 'assets/icons_refactor/SideMenu/availability.svg';
import {ReactComponent as SideReference} from 'assets/icons_refactor/SideMenu/side-reference.svg';
import {ReactComponent as SideSoftware} from 'assets/icons_refactor/SideMenu/software.svg';
import {ReactComponent as SideGuidlines} from 'assets/icons_refactor/SideMenu/guidlines.svg';
import {ReactComponent as SideTelegram} from 'assets/icons_refactor/SideMenu/telegram.svg';
import {ReactComponent as SideLogin} from 'assets/icons_refactor/SideMenu/login.svg';
import {ReactComponent as SideKey} from 'assets/icons_refactor/SideMenu/key.svg';
import {ReactComponent as SideLock} from 'assets/icons_refactor/SideMenu/lock.svg';
import {ReactComponent as SideBilling} from 'assets/icons_refactor/SideMenu/side-invoices.svg';
import {ReactComponent as Integration} from 'assets/icons_refactor/SideMenu/integration.svg';
import {ReactComponent as Keys} from 'assets/icons_refactor/SideMenu/keys.svg';
import {ReactComponent as ExternalAccounts} from 'assets/icons_refactor/SideMenu/external-accounts.svg';
import {ReactComponent as Chat} from 'assets/icons_refactor/Client/chat.svg';
import {ReactComponent as Support} from 'assets/icons_refactor/SideMenu/support.svg';

export interface SubMenuItem {
  key: string;
  name: string;
  href?: string;
  to?: string;
  userTypes?: string[];
  icon?: FC;
  type?: string;
  target?: string;
}

export interface MainMenuItem {
  key: string;
  name: string;
  type?: string;
  icon?: FC;
  to?: string;
  href?: string;
  userTypes?: string[];
  submenu?: SubMenuItem[];
  iconComponent?: ReactNode;
  invisible?: boolean;
}

export const controlPanelLinks: SubMenuItem[] = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    to: '/dashboard',
    icon: SideDashboard,
  },
  {
    key: 'invoices',
    name: 'Invoices',
    to: '/member/invoices',
    icon: SideInvoices,
    userTypes: ['Admin'],
  },
  {
    key: 'balance',
    name: 'My Balance',
    to: '/balance',
    userTypes: ['Translator'],
    icon: SideBalance,
  },
  {
    key: 'statement',
    name: 'My Statements',
    to: '/statement',
    icon: SideStatement,
  },
  {
    key: 'messages',
    name: 'Messages',
    to: '/mbox',
    icon: SideMessages,
  },
  {
    key: 'community',
    name: 'Community',
    href: 'https://forum.getblend.com/',
    icon: SideCommunity,
  },
  {
    key: 'credits',
    name: 'Buy more credits',
    href: '/payment/quote/?amount=10000',
    userTypes: ['Admin'],
    icon: SideCredits,
  },
];

export const ProfileSubMenu: SubMenuItem[] = [
  {
    key: 'accountInformation',
    name: 'Account Information',
    to: '/profile#account',
    userTypes: ['Customer', 'Translator'],
    icon: SideAccountInfo,
  },
  {
    key: 'languages',
    name: 'Language Skills',
    to: '/profile#languages',
    icon: SideLanguageSkills,
  },
  {
    key: 'expertise',
    name: 'Expertise',
    to: '/profile#expertise',
    icon: SideExpertise,
  },
  {
    key: 'uploads',
    name: 'Resume & Certificates',
    to: '/profile#uploads',
    icon: SideCertificates,
  },
  {
    key: 'time',
    name: 'Availability',
    to: '/profile#time',
    icon: SideAvailability,
  },
  {
    key: 'references',
    name: 'References',
    to: '/profile#references',
    icon: SideReference,
  },
  {
    key: 'software_services',
    name: 'Software & Services',
    to: '/profile#software_services',
    icon: SideSoftware,
  },
  {
    key: 'mailing',
    name: 'Mailing Preferences',
    to: '/profile#mailing',
    icon: SideMessages,
  },
  {
    key: 'translator_guidelines',
    name: 'Translator Guidelines',
    to: '/profile#translator_guidelines',
    icon: SideGuidlines,
  },
  {
    key: 'telegram',
    name: 'Telegram Notifications',
    to: '/profile#telegram',
    icon: SideTelegram,
  },
  {
    key: 'tfa_enable',
    name: 'Enable 2 step login',
    to: '/profile#tfa_enable',
    icon: SideLogin,
  },
  {
    key: 'tfa_disable',
    name: 'Disable 2 step login',
    to: '/profile#tfa_disable',
    icon: SideLogin,
  },
  {
    key: 'password',
    name: 'Password Update',
    to: '/profile#password',
    icon: SideKey,
  },
  {
    key: 'privacy_policy',
    name: 'Privacy Policy',
    to: '/profile#privacy_policy',
    icon: SideLock,
  },
  {
    key: 'beneficiary_account',
    name: 'Sub-Accounts',
    to: '/profile#sub_account',
    icon: SideCommunity,
  },
];

export const translationLinks: SubMenuItem[] = [
  {
    key: 'ourPartners',
    name: 'Partners & Integrations',
    href: 'https://www.getblend.com/integrations/',
    target: '_blank',
    userTypes: ['Customer'],
    icon: Integration,
  },
  // TODO temporary hide OHTWEB-23553
  // {
  //   key: 'tmxFiles',
  //   name: 'Translation Memory',
  //   to: '/profile#tmxFiles',
  // },
  {
    key: 'apiDocumentation',
    name: 'API Documentation',
    href: 'https://apidocs.getblend.com/',
    target: '_blank',
    userTypes: ['Customer'],
    icon: SideSoftware,
  },
  {
    key: 'apikeys',
    name: 'API Keys',
    to: '/profile#apikeys',
    icon: Keys,
  },
  // TODO temporary hide OHTWEB-23553
  // {
  //   key: 'terminology',
  //   name: 'Terminology / Glossary',
  //   to: '/profile#terminology',
  // },
];

export const MyTeamSubMenu: SubMenuItem[] = [
  {
    key: 'myTeam',
    name: 'MyTeam',
    href: '/myteam',
    icon: SideCommunity,
  },
];

export const SupportSubMenu: SubMenuItem[] = [
  {
    key: 'contactSupport',
    name: 'Contact support',
    href: 'https://help.getblend.com/hc/en-us/requests/new',
    target: '_blank',
    icon: Support,
  },
  {
    key: 'helpCenter',
    name: 'Help Center',
    href: 'https://help.getblend.com/hc/en-us',
    target: '_blank',
    icon: SideGuidlines,
  },
];

export const translationManagmentSubmenu: SubMenuItem[] = [
  {
    key: 'custom_notes',
    name: 'Manage Project Notes',
    to: '/profile#custom_notes',
    icon: SideReference,
  },
];

export const financialsSubmenu: SubMenuItem[] = [
  {
    key: 'invoices',
    name: 'Invoices',
    to: '/member/invoices',
    userTypes: ['Customer', 'Admin'],
    icon: SideInvoices,
  },
  {
    key: 'statements',
    name: 'Statements',
    to: '/customer-statement',
    userTypes: ['Customer', 'Admin'],
    icon: SideStatement,
  },
  {
    key: 'credits',
    name: 'Buy more credits',
    href: '/payment/quote/?amount=10000',
    userTypes: ['Customer', 'Admin'],
    icon: SideCredits,
  },
];

export const customerProfileSubmenu: SubMenuItem[] = [
  {
    key: 'accountInformation',
    name: 'Account Information',
    to: '/profile#account',
    userTypes: ['Customer', 'Translator'],
    icon: SideAccountInfo,
  },
  {
    key: 'billing',
    name: 'Billing Address',
    to: '/profile#billing',
    icon: SideBilling,
  },
  {
    key: 'mailing',
    name: 'Mailing Preferences',
    to: '/profile#mailing',
    icon: SideMessages,
  },
  {
    key: 'beneficiary_account',
    name: 'Sub Accounts',
    to: '/profile#sub_account',
    icon: ExternalAccounts,
  },
  {
    key: 'messages',
    name: 'Messages',
    to: '/mbox',
    icon: Chat,
  },
];

export const customerSecuritySubmenu: SubMenuItem[] = [
  {
    key: 'tfa_enable',
    name: 'Enable 2 step login',
    to: '/profile#tfa_enable',
    icon: SideLogin,
  },
  {
    key: 'tfa_disable',
    name: 'Disable 2 step login',
    to: '/profile#tfa_disable',
    icon: SideLogin,
  },
  {
    key: 'password',
    name: 'Password Update',
    to: '/profile#password',
    icon: SideKey,
  },
  {
    key: 'privacy_policy',
    name: 'Privacy Policy',
    to: '/profile#privacy_policy',
    icon: SideLock,
  },
];
