import {useState, useEffect} from 'react';
import useCurrencyQuery from './useChangeCurrencyQuery';

export default function useChangCurrency() {
  const [isSuccess, setSuccess] = useState(false);
  const [currency, setCurrency] = useState('');

  const {refetch} = useCurrencyQuery(currency, setSuccess);

  useEffect(() => {
    currency && refetch();
  }, [currency, refetch]);

  return {isSuccess, setSuccess, setCurrency, refetch, currency};
}
