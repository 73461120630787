import {useState, useEffect} from 'react';
import {useMutation} from 'react-query';
import api from 'api';

export interface AdminProject {
  id: string;
  exist: boolean;
}

export interface ProjectActionResult {
  result: boolean;
}

export default function useProjectAction() {
  const [project, setProject] = useState<AdminProject>({id: '', exist: false});
  const {mutate: mutateIsProjectExist, isLoading: isProjectLoading} = useMutation(api.admin.postSearchProject, {
    onSuccess: ({result}: ProjectActionResult) => setProject((prevState) => ({...prevState, exist: result})),
  });

  useEffect(() => {
    if (!project.id) return;
    const fd = new FormData();
    fd.append('PID', project.id);
    mutateIsProjectExist({fd});
  }, [project.id, mutateIsProjectExist]);

  const handleProjectChange = (id: string) => {
    setProject((prevState) => ({...prevState, id}));
  };
  const redirectToTheProject = () => {
    window.location.href = `${window.location.origin}/project/${project.id}`;
  };

  return {
    handleProjectChange,
    project,
    redirectToTheProject,
    isProjectLoading,
  };
}
