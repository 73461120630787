import {UseQueryResult, useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'utils/const';
import api from 'api';
import {IUserDetails} from './useUserAction';

export function useUserDetailsQuery(id: string): UseQueryResult<IUserDetails> {
  return useQuery(QUERY_KEYS.USER_DETAILS_FOR_ADMIN, () => api.admin.getUserDetails(id).then(({data}) => data), {
    enabled: false,
  });
}

export function useClearUserDetails() {
  const queryClient = useQueryClient();
  return () => queryClient.setQueryData(QUERY_KEYS.USER_DETAILS_FOR_ADMIN, {});
}
