import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import Submarine from 'assets/images/adminSubmarine.png';
import {useUser} from 'context/UserData';
import BaseImage from '../../BaseComponents/BaseImage';
import AdminDetails from './AdminActionsView';
import {getItem} from 'utils/localStorage';

interface IconPosition {
  x: number;
  y: number;
}

const AdminActions = () => {
  const {
    adminSubmarine: {openAdminActions},
  } = useUser();

  const maxY: number = window.innerHeight - 100;

  const [iconPosition, setIconPosition] = useState<IconPosition>({
    x: parseInt(getItem('adminIconX') || '10'),
    y: parseInt(getItem('adminIconY') || `${maxY / 2}`),
  });

  const handleIconDrag = (_: DraggableEvent, {x, y}: DraggableData) => {
    setIconPosition({x, y});

    try {
      localStorage.setItem('adminIconX', String(x));
      localStorage.setItem('adminIconY', String(y));
    } catch (error) {
      console.error("can't set submarine position", error);
    }
  };

  const openAdminClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      openAdminActions();
    },
    [openAdminActions]
  );

  useEffect(() => {
    if (
      iconPosition.x < 10 ||
      iconPosition.x > window.innerWidth - 50 ||
      iconPosition.y < 0 ||
      iconPosition.y > maxY + 55
    ) {
      setIconPosition({
        x: 10,
        y: maxY / 2,
      });
    }
  }, []);

  return (
    <Draggable
      position={iconPosition}
      onStop={handleIconDrag}
      cancel="select"
      handle=".draggable-submarine"
      scale={1}
      bounds="parent"
    >
      <AdminAction className="draggable-submarine" onDragStart={(e: MouseEvent<HTMLDivElement>) => e.preventDefault()}>
        <div onClick={openAdminClick}>
          <Image src={Submarine} />
        </div>

        <AdminDetails />
      </AdminAction>
    </Draggable>
  );
};

const AdminAction = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
`;

const Image = styled(BaseImage)`
  width: 90px;
  height: 55px;
`;

export default AdminActions;
