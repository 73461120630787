import React, { FC } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { ModalMode } from '../../AuthModal/typesAuthModal';
import useMobile from 'hooks/useMobile';

type Props = {
  handleOpenModal: (type: ModalMode) => void;
};

const AuthButtons: FC<Props> = ({ handleOpenModal }) => {
  const { isMobile } = useMobile() as { isMobile: boolean }; // Explicitly typing the return value
  return (
    <Wrapper>
      <LoginButton onClick={() => handleOpenModal('login')}>Sign in</LoginButton>

      {!isMobile ? (
        <RegisterButton onClick={() => handleOpenModal('signup')}>
          <span style={{ color: 'white' }}>Register</span>
        </RegisterButton>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display: flex;
    padding-right: 10px;
`;

const LoginButton = styled(Button)<{onClick: () => void}>`
    background: #5857ff;
    color: ${({ theme }) => theme.colors.grey000};
    border: none;
    font-weight: bold;
    margin-right: 5px;
    white-space: nowrap;
    margin-left: -25px;
`;

const RegisterButton = styled(Button)`
    background: #454d55;
    color: ${({ theme }) => theme.colors.grey000};
    border: none;
`;

export default AuthButtons;
