import {useEffect, useRef, RefObject, useCallback} from 'react';

const useSwipe = (
  elementRef: RefObject<HTMLElement>,
  onSwipeLeft?: () => void,
  onSwipeRight?: () => void,
  disableSwipe?: boolean
) => {
  const startXRef = useRef<number | null>(null);

  const handleTouchStart = useCallback(
    (event: TouchEvent) => {
      if (!disableSwipe) {
        startXRef.current = event.touches[0].clientX;
      }
    },
    [disableSwipe]
  );

  const handleTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (!disableSwipe) {
        const endX = event.changedTouches[0].clientX;
        const startX = startXRef.current;

        if (startX && startX - endX > 50) {
          onSwipeLeft && onSwipeLeft();
        } else if (startX && endX - startX > 50) {
          onSwipeRight && onSwipeRight();
        }

        startXRef.current = null;
      }
    },
    [disableSwipe, onSwipeLeft, onSwipeRight]
  );

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [elementRef, handleTouchStart, handleTouchEnd]);
};

export default useSwipe;
