import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import {font_medium} from 'theme/fonts';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  children?: ReactNode | string;
  href?: string;
  disableRipple?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}

const BaseButton: FC<Props> = ({children, disabled, href, onClick, disableRipple, type, className, ...other}) => (
  <Wrapper
    href={href}
    onClick={onClick}
    disabled={disabled}
    disableRipple={disableRipple}
    type={type}
    className={className}
    {...other}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled(Button)`
  ${font_medium};
  display: flex;
  align-items: center;
`;

export default BaseButton;
