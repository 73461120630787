import {useState, useEffect, useRef, RefObject, useCallback} from 'react';

const useDoubleTap = <T extends HTMLElement>(ref: RefObject<T>, delay = 400): boolean => {
  const [tapCount, setTapCount] = useState<number>(0);
  const tapTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleTouchStart = useCallback(() => {
    if (tapTimeout.current) {
      clearTimeout(tapTimeout.current);
    }

    tapTimeout.current = setTimeout(() => {
      tapTimeout.current = null;
      setTapCount(0); // Reset tap count after delay
    }, delay);
  }, [delay]);

  const handleTouchEnd = useCallback(() => {
    if (tapTimeout.current) {
      clearTimeout(tapTimeout.current);
      tapTimeout.current = null;

      setTapCount((prevCount) => {
        const newCount = prevCount + 1;

        if (newCount > 2) {
          // Double tap detected
          return 0;
        }

        return newCount;
      });
    }
  }, []);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [ref, delay, handleTouchStart, handleTouchEnd]);

  return tapCount === 2;
};

export default useDoubleTap;
