import React, {FC, useState, useCallback, ReactNode, MouseEvent} from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  options: OptionsProps[];
  children: ReactNode;
}

interface OptionsProps {
  item: string;
  action?: (item: any) => void;
  icon?: ReactNode;
}

const BaseMenu: FC<Props> = ({options, children, ...other}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e?.stopPropagation();
      if (anchorEl !== e.currentTarget) {
        setAnchorEl(e.currentTarget);
      }
    },
    [anchorEl]
  );

  const handleClose = useCallback((e: any) => {
    e?.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div onClick={handleClick} {...other}>
        {children}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{onMouseLeave: handleClose}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorReference="anchorEl"
        autoFocus={false}
      >
        <MenuItemWrapper>
          {options.map(({item, action, icon}) => (
            <Item key={item} onClick={action}>
              {icon && icon}
              {item}
            </Item>
          ))}
        </MenuItemWrapper>
      </Menu>
    </>
  );
};

const MenuItemWrapper = styled.div`
  padding: 8px;
  box-sizing: border-box;
`;

const Item = styled(MenuItem)`
  background-color: ${({theme}) => theme.colors.grey000};
  font-size: 16px;
  color: ${({theme}) => theme.colors.grey100};
  min-width: 180px;
  column-gap: 10px;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey030};
  }
`;

export default BaseMenu;
