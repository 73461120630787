import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import styled, {css} from 'styled-components';
import useTracking from 'context/Tracking/useTracking';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLink from 'components/BaseComponents/BaseLink';
import SidebarSubMenuItem from './components/SidebarSubMenuItem';
import ChatActiveMessages from 'pages/BlendTalk/components/ChatActiveMessages';
import {MainMenuItem, SubMenuItem} from '../SideBarLinks';
import {useUser} from 'context/UserData';
import {useStores} from 'hooks/useStores';
import useMobile from 'hooks/useMobile';
import {GTMEvents} from 'utils/globalEvents';
import {ReactComponent as Toggle} from 'assets/icons_refactor/Common/small-arrow.svg';
import {getLinkColor} from './SidebarUtils';
import {font_large, boldWeight} from 'theme/fonts';

interface SidebarProps {
  isStartOpen: boolean;
  item: MainMenuItem;
  key: string;
  availableSubMenus: SubMenuItem[];
  openSideBar: (key?: string) => void;
}

const {click_chat} = GTMEvents;

const SidebarItem = observer(({item, isStartOpen, availableSubMenus, openSideBar}: SidebarProps) => {
  const {
    dashboardStore: {
      translator_info: {status},
    },
  } = useStores();
  const {isTablet} = useMobile();
  const {userData} = useUser();
  const {gtmTrack} = useTracking();

  const {pathname, hash} = useLocation();

  const [submenu, setSubmenu] = useState(false);
  useEffect(() => {
    isStartOpen && setSubmenu(true);
  }, [isStartOpen]);

  const toggleShowMenu = useCallback(() => setSubmenu((prev) => !prev), []);

  const isSubMenuSelectedItem = useMemo(
    () =>
      item?.submenu?.find(
        ({to, href}) => (!!to && (pathname + hash).includes(to)) || (!!href && (pathname + hash).includes(href))
      )
        ? 1
        : 0,
    [pathname, hash, item?.submenu]
  );

  const getMenuItemClasses = useMemo(
    () => `${submenu ? 'isOpen' : ''} ${isStartOpen ? 'active' : ''} `,
    [submenu, isStartOpen]
  );

  const {key, icon, to, name, href, iconComponent} = item;

  const isSelected = useMemo(() => (to && pathname.includes(to) ? 1 : 0), [to, pathname]);

  const renderExternalLink = useMemo(
    () =>
      href ? (
        <ExternalLink key={key} href={href} target="_blank">
          {name}
        </ExternalLink>
      ) : (
        <MenuItemTitle>{name}</MenuItemTitle>
      ),
    [href, key, name]
  );

  const handleClickMenu = useCallback(() => {
    if (item.submenu) {
      toggleShowMenu();
    } else {
      isTablet && openSideBar();
    }

    if (item.key === 'blendTalk') {
      userData?.type && gtmTrack({event: click_chat, user_role: userData?.type});
    }
  }, [isTablet, item, openSideBar, toggleShowMenu, userData?.type]);

  return (
    <Wrapper data-qa-auto={`${key}-sidebar-section`} isOpen={submenu}>
      <>
        <MenuItem
          className={getMenuItemClasses}
          selected={isSubMenuSelectedItem}
          open={isStartOpen ? 1 : 0}
          onClick={handleClickMenu}
        >
          {!!icon && <Icon icon={icon} selected={isSelected} boxW={20} boxH={20} />}
          {iconComponent && <IconComponent selected={isSelected}>{iconComponent}</IconComponent>}
          {to ? (
            <InternalLink key={key} to={to} selected={isSelected}>
              {name}
            </InternalLink>
          ) : (
            renderExternalLink
          )}
          {item.key === 'blendTalk' && <ChatActiveMessages sideBar show />}
          {item.submenu && <Arrow icon={Toggle} />}
        </MenuItem>

        {submenu &&
          availableSubMenus.map((submenuItem) => (
            <SidebarSubMenuItem key={submenuItem.key} item={submenuItem} status={status} openSideBar={openSideBar} />
          ))}
      </>
    </Wrapper>
  );
});

const Wrapper = styled.div<{isOpen: boolean}>`
  transition: color 0.2s;
  border-bottom: 1px solid ${({theme, isOpen}) => (isOpen ? theme.colors.grey020 : 'transparent')};
`;

const LinkStyle = css`
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const ExternalLink = styled(BaseLink)<{href: string}>`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
`;

const InternalLink = styled(Link)<{selected?: number}>`
  ${LinkStyle};
  color: ${({theme, selected}) => theme.colors[getLinkColor(selected)]};

  &:hover {
    color: ${({theme, selected}) => theme.colors[getLinkColor(selected)]};
  }
`;

const Icon = styled(BaseIcon)<{selected?: number}>`
  width: 20px !important;
  height: 20px !important;
  fill: ${({
    theme: {
      colors: {grey090, blue100},
    },
    selected,
  }) => (selected ? blue100 : grey090)};

  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    fill: ${({
      theme: {
        colors: {grey090, blue100},
      },
      selected,
    }) => (selected ? blue100 : grey090)};
  }
`;

const SubIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${({theme}) => theme.colors.grey080};
`;

const Arrow = styled(SubIcon)`
  cursor: pointer;
`;

const MenuItemTitle = styled.div`
  cursor: pointer;
  user-select: none;
`;

const MenuItem = styled.div<{selected?: number; open?: number}>`
  ${font_large};
  ${boldWeight};
  position: relative;
  display: grid;
  align-items: center;
  padding: 7px 20px;
  grid-template-columns: 20px auto 15px;
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  text-decoration: none;
  color: ${({
    theme: {
      colors: {blue100, grey090},
    },
    selected,
  }) => (selected ? blue100 : grey090)};
  background-color: ${({
    theme: {
      colors: {blue020},
    },
    selected,
  }) => (selected ? blue020 : 'inherit')};

  ${Icon} {
    fill: ${({
      theme: {
        colors: {blue100},
      },
      selected,
    }) => (selected ? blue100 : '')};
  }

  ${Arrow} {
    transform: ${({open}) => `rotate(${open ? 'rotate(-180deg)' : 'rotate(0deg)'})`};
  }
`;

const IconComponent = styled.div<{selected: number}>`
  svg {
    fill: ${({
      theme: {
        colors: {grey090, blue100},
      },
      selected,
    }) => (selected ? blue100 : grey090)};
  }

  div {
    div {
      top: 15px;
      left: 10px;
    }
  }
`;

export default SidebarItem;
