import React, {FC} from 'react';
import TextField from '@mui/material/TextField';
import {TextFieldProps as MuiTextFieldPropsType} from '@mui/material/TextField/TextField';

type BaseDatepickerInputProps = MuiTextFieldPropsType & {
  'data-qa-auto'?: string;
};

const BaseDatepickerInput: FC<BaseDatepickerInputProps> = ({'data-qa-auto': dataQaAuto, ...props}) => (
  <TextField {...props} data-qa-auto={dataQaAuto} />
);

export default BaseDatepickerInput;
