import React, {useState} from 'react';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import AuthForm from './Form';
import AuthSocial from './AuthSocial';
import ModalTitle from './Title';
import AuthModalFooter from './Footer';
import {ModalMode} from './typesAuthModal';
import {useAuth, useUser} from 'context/UserData';
import ConstTheme from 'theme';

type Props = {
  mode: ModalMode;
  handleCloseModal: () => void;
  handleOpenModal: (type: ModalMode) => void;
  needSocialButtons?: boolean;
  config: any;
  registrationPage: any;
};

type Title = {
  [key in 'signup' | 'login' | 'extraUserData' | '']: string;
};

const AuthModal = ({
  mode,
  handleCloseModal,
  handleOpenModal,
  config,
  needSocialButtons = false,
  registrationPage,
}: Props) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useAuth();
  const {userData} = useUser();

  const title = {
    signup: 'Create your account',
    login: 'Sign In',
    extraUserData: `Hi ${userData?.name || ''}`,
  } as Title;

  const [emailInput, setCurrentEmail] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(true);

  return (
    <DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={!!mode}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <ModalTitle id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {title[mode]}
      </ModalTitle>
      <ModalContent>
        {needSocialButtons && <AuthSocial />}
        <AuthForm
          handleClose={handleCloseModal}
          emailUpdatedHandler={setCurrentEmail}
          config={config}
          mode={mode}
          registrationPage={registrationPage}
          agreeTermsValue={agreeTerms}
        />

        {(mode === 'signup' || mode === 'login') && (
          <AuthModalFooter
            mode={mode}
            handleOpenModal={handleOpenModal}
            email={emailInput}
            agreeTermsValue={agreeTerms}
            setAgreeTermsValue={setAgreeTerms}
          />
        )}
      </ModalContent>
    </DialogItem>
  );
};

const ModalContent = styled(MuiDialogContent)`
  padding: 8px 30px;

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

const DialogItem = styled(Dialog)`
  &.MuiDialog-paper {
    overflow: visible;
    max-width: 560px;
    border-radius: 0.75rem;

    @media ${({theme}) => theme.breakpoints.maxSm} {
      margin: 10px;
      width: 100%;
    }
  }
`;
export default AuthModal;
