import React, {FC, ChangeEvent, ReactNode, MouseEvent, FocusEventHandler, forwardRef, Ref} from 'react';
import styled from 'styled-components';
import Input from '@mui/material/Input';
import {font_medium} from 'theme/fonts';

interface Props {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  name?: string;
  type?: string;
  endAdornment?: ReactNode;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  error?: boolean;
  inputProps?: object;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const BaseSimpleInput: FC<Props> = forwardRef(
  (
    {
      name,
      type,
      placeholder,
      disabled,
      value,
      onChange,
      autoFocus,
      endAdornment,
      onClick,
      onBlur,
      error,
      inputProps,
      onFocus,
      ...other
    },
    ref?: Ref<HTMLInputElement>
  ) => (
    <Wrapper
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoFocus={autoFocus}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      inputProps={inputProps}
      endAdornment={endAdornment}
      error={error}
      inputRef={ref}
      {...other}
    />
  )
);

const Wrapper = styled(Input)<{ref?: Ref<HTMLInputElement>}>`
  ${font_medium};
  color: ${({theme}) => theme.colors.green090};
  width: 100%;

  &:after,
  &:before,
  &:hover:before {
    border: none !important;
  }

  & > input {
    padding: 7px;
    border-radius: 4px;
    border: 1px solid ${({theme}) => theme.colors.green075};

    &:focus {
      border: 1px solid ${({theme}) => theme.colors.blue100};
      border-radius: 4px;
    }
  }
`;

export default BaseSimpleInput;
