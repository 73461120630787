import React, {FC, ReactNode} from 'react';
import Link from '@mui/material/Link';

interface Props {
  children?: ReactNode;
  href: string;
  target?: string;
  onClick?: () => void;
}

const BaseLink: FC<Props> = ({children, href, target = '_self', onClick, ...other}) => (
  <Link href={href} rel="noopener noreferrer" target={target} onClick={onClick} {...other}>
    {children}
  </Link>
);

export default BaseLink;
