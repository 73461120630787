import React, {FC, ReactNode, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useUser} from 'context/UserData';
import NotFoundView from 'pages_refactor/NotFound/NotFoundView';
import {UserType} from './AvailabilityCheckerUtils';

interface PrivateLayoutProps {
  children: ReactNode;
  allowedUserTypes: UserType[];
}

const AvailabilityChecker: FC<PrivateLayoutProps> = ({children, allowedUserTypes}) => {
  const {userData, isLoggedIn} = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate({pathname: '/auth'}, {state: {from: location}});
    }
  }, [isLoggedIn, location]);

  const isShowNotFound = useMemo(
    () => isLoggedIn && !allowedUserTypes.includes(userData?.type?.toLowerCase() as UserType),
    [allowedUserTypes, userData, isLoggedIn]
  );

  return <>{isShowNotFound ? <NotFoundView /> : children}</>;
};

export default AvailabilityChecker;
