import { useState, useEffect, useRef } from 'react';

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);
  const lastValue = useRef(value);

  useEffect(() => {
    if (lastValue.current !== value) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        lastValue.current = value;
      }, delay);

      return () => clearTimeout(handler);
    }
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
