import {memo, ComponentType} from 'react';
import {isEqual} from 'utils/global';

const areEqual = (debug: boolean) => (prevProps: any, nextProps: any) =>
  Object.keys(nextProps).reduce((acc, key) => {
    if (debug) {
      console.log(key, isEqual(prevProps[key], nextProps[key]), prevProps[key], nextProps[key]);
    }
    return acc && isEqual(prevProps[key], nextProps[key]);
  }, true);

const BaseMemoHoc = (Component: ComponentType<any>, debug = false) => memo(Component, areEqual(debug));

export default BaseMemoHoc;
