import React, {useState, FC} from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

import useChangCurrency from './useChangCurrency';
import {currencyList} from './AdminActionsUtils';

export const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const CurrencySelect: FC = () => {
  const [isSelectVisible, setSelectVisible] = useState(false);

  const {isSuccess, setSuccess, setCurrency, currency} = useChangCurrency();

  const handleClose = () => setSuccess(false);

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.target?.value) {
      setCurrency(e.target.value);
    }
  };

  return (
    <Box margin="10px 0px">
      <Button variant="outlined" className="prevent-drag" onClick={() => setSelectVisible(!isSelectVisible)}>
        Change currency
      </Button>

      {isSelectVisible && (
        <Box marginTop="10px">
          <select className="prevent-drag" onChange={handleCurrencyChange} value={currency || 'USD'}>
            {currencyList.map((currencyVal) => (
              <option key={currencyVal} value={currencyVal}>
                {currencyVal}
              </option>
            ))}
          </select>
        </Box>
      )}
      <Snackbar
        open={Boolean(isSuccess)}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            Currency updated
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
};

export default CurrencySelect;
