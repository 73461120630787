import React from 'react';
import styled from 'styled-components';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

type Props = {
  children?: React.ReactNode;
  onClose: () => void;
  id: string;
  disableClose: boolean;
};

const ModalTitle = (props: Props) => {
  const {children, onClose, disableClose = false, ...other} = props;

  return (
    <Title {...other}>
      <Box>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} disabled={disableClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Typography variant="h4" align="center">
        {children}
      </Typography>
    </Title>
  );
};

const Title = styled(MuiDialogTitle)`
  margin: 0;
  padding: 8px 30px;

  & div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & button {
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;
export default ModalTitle;
