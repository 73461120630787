import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {fetchAuthToken} from 'api/authToken';

export interface UseApiTokenOptions {
  lifetime?: number;
}

const useAuthToken = (options?: UseApiTokenOptions) => {
  const [token, setToken] = useState<string | null>(null);

  const {data: fetchedToken, ...rest} = useQuery({
    queryKey: ['auth/token'],
    queryFn: () => fetchAuthToken(),
    suspense: true,
    staleTime: options?.lifetime || 10 * 60 * 1000,
    enabled: !token,
  });

  useEffect(() => {
    if (fetchedToken && !token) {
      setToken(fetchedToken as string);
    }
  }, [fetchedToken, token]);

  return {token, ...rest};
};

export default useAuthToken;
