import React, {FC} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import {font_large} from 'theme/fonts';

type Props = {
  text: string;
  handleSubmit: () => void;
  disabled: boolean;
  displayLoader: boolean;
};

const SubmitButton: FC<Props> = ({text, handleSubmit, disabled, displayLoader}) =>
  displayLoader ? (
    <Loader />
  ) : (
    <Button onClick={handleSubmit} disabled={disabled} data-qa-auto="submit-button">
      {text}
    </Button>
  );

const Loader = styled(BaseLoader)`
  display: flex;
  margin: auto auto 10px;
`;

export const Button = styled(BaseButton)`
  ${font_large};
  background: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  border-radius: 30px;
  height: 48px;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    background: ${({theme}) => theme.colors.blue090};
  }

  &:disabled {
    background: rgb(218, 219, 252);
  }
`;

export default SubmitButton;
